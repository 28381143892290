import type { SVGProps } from 'react'

export const Hamburger = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='20'
    height='17'
    viewBox='0 0 20 17'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <rect width='20' height='3' />
    <rect y='7' width='20' height='3' />
    <rect y='14' width='20' height='3' />
  </svg>
)
