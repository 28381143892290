import { breakpoint } from 'src/styles'
import Image from 'next/legacy/image'
import { Link } from 'src/components/Link'
import { widths, cloudflareLoader } from 'src/utils/image'

const logoUrl = cloudflareLoader({ src: '/obe.png', width: widths.logo })

interface LogoBlackProps {
  to: string
}

export const LogoBlack = ({ to }: LogoBlackProps) => (
  <Styles.Container to={to} tracking={{ context: 'logo' }}>
    <Image src={logoUrl} alt='obé Fitness' layout='fill' priority unoptimized />
  </Styles.Container>
)

const Styles = {
  Container: styled(Link)`
    display: block;
    height: 37px;
    position: relative;
    width: 89px;
    ${breakpoint.mediumAndDown} {
      height: 31px;
      width: 74px;
    }
  `,
}
