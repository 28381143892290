import { useSearchParams } from 'next/navigation'
import { useEffect, useMemo, useState } from 'react'

import { getStoredCouponCode, setStoredStripeCoupon } from 'src/hooks/useStripeCoupon'

export function useStoredCoupon() {
  const searchParams = useSearchParams()

  const couponFromParams = useMemo(() => searchParams.get('utm_content'), [searchParams])

  const [storedCoupon, setStoredCoupon] = useState(couponFromParams || getStoredCouponCode() || '')

  useEffect(() => {
    if (!couponFromParams || couponFromParams === getStoredCouponCode()) return
    setStoredStripeCoupon(couponFromParams)
    setStoredCoupon(couponFromParams)
  }, [couponFromParams])

  return {
    storedCoupon,
  }
}
