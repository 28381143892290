import type { DetailedHTMLProps, HTMLAttributes } from 'react'

import { useDrawerContext } from 'src/components/Display/Drawer/context/DrawerContext'
import { Collapse } from 'src/components/Display/Collapse'

export const DrawerContent = ({
  children,
  ...props
}: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
  const { isOpen, id, width } = useDrawerContext()

  return (
    <StyledCollapse id={id} isOpen={isOpen} isHorizontal width={width} {...props}>
      <div className="px-6 pt-6">{children}</div>
    </StyledCollapse>
  )
}

const StyledCollapse = styled(Collapse)`
  position: absolute;
  right: 0;
  background-color: white;
  height: 100vh;
  border-top: 1px solid var(--neutrals400);
`
