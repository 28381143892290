import { useCallback, useEffect, useState, type ReactNode } from 'react'

import { DrawerContext } from 'src/components/Display/Drawer/context/DrawerContext'
import { useMediaQuery } from 'src/hooks/window'
import { breakpointMediaShort } from 'src/styles'

interface Props {
  id?: string
  width?: number
  onToggle?: (open: boolean) => void
  children?: ReactNode
}

export const DrawerProvider = ({ id, width, onToggle, children }: Props) => {
  const isLargeAndUp = useMediaQuery(breakpointMediaShort.largeAndUp)
  const [isOpen, setIsOpen] = useState(false)

  const toggle = useCallback(() => {
    setIsOpen(!isOpen)
    onToggle?.(!isOpen)
  }, [isOpen, onToggle])

  useEffect(() => {
    // Automatically close drawer on big screens
    if (isLargeAndUp && isOpen) {
      setIsOpen(false)
      onToggle?.(false)
    }
  }, [isLargeAndUp, isOpen, onToggle])

  return (
    <DrawerContext.Provider
      value={{
        isOpen,
        toggle,
        id,
        width,
      }}
    >
      {children}
    </DrawerContext.Provider>
  )
}
