import type { DetailedHTMLProps, HTMLAttributes } from 'react'
import { twMerge } from 'tailwind-merge'

import { useDrawerContext } from 'src/components/Display/Drawer/context/DrawerContext'

export const DrawerOverlay = ({
  className,
  ...props
}: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
  const { isOpen, toggle } = useDrawerContext()

  return (
    <div
      onClick={() => toggle()}
      className={twMerge(
        `fixed bottom-0 left-0 right-0 top-0 z-[-1] cursor-pointer bg-black/40
        transition-[visibility,opacity] duration-200 ease-linear`,
        className,
        [isOpen ? 'visible opacity-100' : 'invisible opacity-0'],
      )}
      {...props}
    />
  )
}
