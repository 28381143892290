import useWindowScrollPosition from '@rehooks/window-scroll-position'
import type { HTMLAttributes, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

interface Props extends HTMLAttributes<HTMLDivElement> {
  children?: ReactNode
}

export const MorphismWrapper = ({ className, children }: Props) => {
  const { y: scrollY } = useWindowScrollPosition()

  return (
    <div
      className={twMerge(
        'fixed left-0 right-0 top-0 z-[1000] flex transition-all duration-200 ease-[ease-out]',
        [
          scrollY > 0
            ? 'border-b border-[var(--neutrals400)] bg-[rgba(249,246,255,0.5)] backdrop-blur-[6px]'
            : 'border-b border-transparent bg-transparent',
        ],
        className,
      )}
    >
      {children}
    </div>
  )
}
