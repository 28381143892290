import { useContext } from 'react'
import { SignUpContext } from 'src/hooks/useSignUpContext/SignUpContextProvider'

export const useSignUpContext = () => {
  const context = useContext(SignUpContext)
  if (!context) {
    throw new Error('useSignUpContext must be used within a SignUpContextProvider')
  }
  return context
}
