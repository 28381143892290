'use client'

import { createContext, useCallback, useState, type ReactNode } from 'react'
import useSWR from 'swr'

import type { Analytics } from 'src/hooks/useSignUpContext/types'
import useUser from 'src/hooks/useUser'
import { DEFAULT_ANNUAL_PLAN, getPlans } from 'src/models/plan'
import { storage } from 'src/storage'
import type { Plan } from 'src/types/Plan'

interface SignUpContextState {
  analytics: Analytics
  selectedPlanId: string | null
  selectedPlan: Plan | null
  plans: Plan[]
  isResubscribing: boolean
  isLoadingPlans: boolean
  onChangeSelectedPlan: (newPlan: Plan | null) => void
}

export const SignUpContext = createContext<SignUpContextState | null>(null)

interface SignUpContextProviderProps {
  analytics?: Analytics
  children: ReactNode
}

export const SignUpContextProvider = ({
  analytics = null,
  children,
}: SignUpContextProviderProps) => {
  const { currentUser } = useUser()

  const planIdentifierFromStorage =
    storage.session.getItem('selectedPlan') || storage.local.getItem('selectedPlan')

  const { data: plansData, isValidating: isLoadingPlans } = useSWR<Plan[]>(
    ['/api/v3/plans'],
    () => getPlans(),
    {
      revalidateOnFocus: false,
      onSuccess(data) {
        if (!data.length) return
        let newSelectedPlan: Plan | null = null
        if (planIdentifierFromStorage) {
          newSelectedPlan = getPlanById(data, planIdentifierFromStorage)
        }
        if (!newSelectedPlan) {
          newSelectedPlan = getPlanById(data, DEFAULT_ANNUAL_PLAN)
        }
        setSelectedPlan(newSelectedPlan)
        if (newSelectedPlan) {
          storage.local.setItem('selectedPlan', newSelectedPlan.planIdentifier)
        }
      },
    },
  )

  const [selectedPlan, setSelectedPlan] = useState<Plan | null>(
    getPlanById(plansData, planIdentifierFromStorage) ||
      getPlanById(plansData, DEFAULT_ANNUAL_PLAN),
  )

  const onChangeSelectedPlan = useCallback((newSelectedPlan: Plan | null) => {
    setSelectedPlan(newSelectedPlan)
    if (newSelectedPlan) {
      storage.local.setItem('selectedPlan', newSelectedPlan.planIdentifier)
    }
  }, [])

  return (
    <SignUpContext.Provider
      value={{
        analytics,
        selectedPlanId: selectedPlan?.planIdentifier || null,
        selectedPlan,
        isLoadingPlans,
        plans: plansData ?? [],
        isResubscribing: currentUser?.account?.hasAnySubscriptions || false,
        onChangeSelectedPlan,
      }}
    >
      {children}
    </SignUpContext.Provider>
  )
}

function getPlanById(plans: Plan[] | undefined, id: string | null) {
  if (!plans || !plans.length || !id) return null
  return plans.find((plan) => plan.planIdentifier === id) ?? null
}
