import { couponErrors } from 'src/providers/CouponProvider/couponErrors'
import type { CouponValidator } from 'src/providers/CouponProvider/types'

export const validateIsError: CouponValidator = ({ coupon }) => {
  if (coupon.isError || !coupon.valid) {
    return {
      isValid: false,
      errorMessage: couponErrors.couponNotValid,
    }
  }
  return { isValid: true }
}

export const validateCanUserEnterCode: CouponValidator = ({ coupon }) => {
  if (!coupon.metadata) return { isValid: true }
  if (coupon.metadata.canUserEnterCode === 'false') {
    return {
      isValid: false,
      errorMessage: couponErrors.couponNotValid,
    }
  }
  return { isValid: true }
}

export const validateWithPtOnly: CouponValidator = ({ coupon }) => {
  if (!coupon.metadata) return { isValid: true }
  if (coupon.metadata.withPtOnly === 'true') {
    return {
      isValid: false,
      errorMessage: couponErrors.couponNotValid,
    }
  }
  return { isValid: true }
}

export const validateAllowedPlans: CouponValidator = ({
  coupon,
  allowedPeriod,
  canUpdateDefaultPlan,
}) => {
  const isAllowed = coupon.allowedPlans.some(
    (allowedPlan) => allowedPlan.periodMonthCount === allowedPeriod,
  )
  if (!isAllowed) {
    if (canUpdateDefaultPlan) {
      const allowedPlan = coupon.allowedPlans.at(0)
      if (allowedPlan) {
        return {
          isValid: false,
          errorMessage: couponErrors.couponNotApplicable,
          defaultPlanPeriod: allowedPlan.periodMonthCount,
        }
      }
    }
    return {
      isValid: false,
      errorMessage: couponErrors.couponNotApplicable,
      shouldLog: true,
    }
  }
  return { isValid: true }
}

export const couponValidators: CouponValidator[] = [
  validateIsError,
  validateCanUserEnterCode,
  validateWithPtOnly,
  validateAllowedPlans,
]
