import { RatingStar } from 'src/components/Icon/RatingStar'

const STARS_ARRAY = Array.from(Array(5))

export const Rating = React.memo(({ className }: { className?: string }) => {
  return (
    <div className={className}>
      <Styles.StarsContainer>
        {STARS_ARRAY.map((_, i) => (
          <Styles.Star key={`star${i}`} />
        ))}
      </Styles.StarsContainer>
      <Styles.Description>4.8 Rating • 10K Reviews</Styles.Description>
    </div>
  )
})

const Styles = {
  StarsContainer: styled.div`
    display: flex;
    justify-content: center;
    gap: 7px;
  `,
  Description: styled.div`
    margin-top: 11px;
    color: var(--neutrals800);
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0.289px;
  `,
  Star: styled(RatingStar)`
    width: 22px;
    height: 22px;
  `,
}
