'use client'

import { createContext, useContext } from 'react'

import type { CouponContextState } from 'src/providers/CouponProvider/types'

export const CouponContext = createContext<CouponContextState | null>(null)

export function useCouponContext() {
  const context = useContext(CouponContext)
  if (!context) {
    throw new Error('useCouponContext must be used within a CouponProvider')
  }
  return context
}
