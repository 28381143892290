import { useMemo } from 'react'

import useUser from 'src/hooks/useUser'
import { useRouter } from 'src/hooks/useRouter'
import { storage } from 'src/storage'

export const useSignupUrl = () => {
  const router = useRouter()
  const { isGuest } = useUser()
  const freemiumSlug = storage.session.getItem('freemiumPartnershipSlug') as string | null

  const signUpUrl = useMemo(() => {
    if (freemiumSlug && router.asPath === '/obe-preview') {
      return `/freemium/${freemiumSlug}`
    }
    if (!isGuest) return '/onboarding/subscription'
    if (router.asPath !== '/obe-preview') return '/obe-quiz/'
    return '/membership/choose-plan'
  }, [freemiumSlug, isGuest, router.asPath])

  return signUpUrl
}
