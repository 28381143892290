import { navStyles } from 'src/components/App/Header/navStyles'
import { links } from 'src/components/App/Header/navigation'
import { Collapse } from 'src/components/Display/Collapse'
import { Drawer } from 'src/components/Display/Drawer/Drawer'
import { Link } from 'src/components/Link'
import { useRouter } from 'src/hooks/useRouter'

const menuItems = [links.aboutUs, links.classes, links.programs] as const

const tracking = { context: 'nav' }
const loginTracking = { context: 'site_nav_button' }

export const LandingHeaderDrawer = () => {
  const { pathname } = useRouter()

  return (
    <Drawer
      id='landing-header-drawer'
      onToggle={(open) => {
        obe.analytics.track(obe.events.user_action, {
          action: `nav_drawer_${open ? 'open' : 'close'}`,
        })
      }}
      content={
        <Drawer.Content className="top-[var(--mobile-header-height)] lg:top-[var(--landing-header-height)]">
          <Drawer.Overlay />
          {menuItems.map(({ action, label, to }) => (
            <Styles.Item key={to}>
              <Styles.Link
                action={action}
                // onClick={toggle}
                synthetic={false}
                to={to}
                tracking={tracking}
                isActive={pathname.startsWith(to)}
              >
                <Styles.Label>{label}</Styles.Label>
              </Styles.Link>
            </Styles.Item>
          ))}
          <Styles.Item key={links.login.to}>
            <Styles.Login
              action={links.login.action}
              // onClick={toggle}
              synthetic={false}
              to={links.login.to}
              tracking={loginTracking}
            >
              <Styles.Label>{links.login.label}</Styles.Label>
            </Styles.Login>
          </Styles.Item>
        </Drawer.Content>
      }
    />
  )
}

const Styles = {
  Collapse: styled(Collapse)`
    position: absolute;
    top: var(--mobile-header-height);
    right: 0;
    background-color: white;
    height: 100vh;
    border-top: 1px solid var(--neutrals400);
  `,
  Container: styled.div`
    padding: 32px 24px 0px 24px;
  `,
  Item: styled.div`
    padding: 12px 0;
  `,
  Label: styled.div`
    position: relative;
    width: fit-content;
  `,
  Link: styled(Link, {
    shouldForwardProp: (prop) => prop !== 'isActive',
  })`
    ${navStyles.landingLink}
    position: relative;

    ${({ isActive }: { isActive?: boolean }) =>
      isActive &&
      css`
        color: var(--primary400);
      `}
  `,
  Login: styled(Link)`
    ${navStyles.landingLink}
    position: relative;
    border-top: 1px solid var(--neutrals400);
    margin-top: 4px;
    padding-top: 24px;
    width: 100%;
  `,
}
