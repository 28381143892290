export interface NavLinkType {
  to: string
  label: string
  action: string | undefined
  synthetic: boolean
}

export const links = {
  blog: {
    action: 'Navigated to Blog',
    label: 'Blog',
    to: '/blog',
    synthetic: true,
  },
  aboutUs: {
    action: 'Navigated to How It Works',
    label: 'About Us',
    to: '/about-us',
    synthetic: true,
  },
  instructors: {
    action: 'Navigated to Instructors',
    label: 'Instructors',
    to: '/instructors',
    synthetic: true,
  },
  login: {
    action: 'Navigated to Login',
    label: 'Login',
    to: '/login',
    synthetic: true,
  },
  logout: {
    action: 'loggedOut',
    label: 'Logout',
    to: '/logout',
    synthetic: true,
  },
  collections: {
    action: 'Navigated to obe Collections',
    label: 'Collections',
    to: '/obe-collections',
    synthetic: true,
  },
  classes: {
    action: 'Navigated to Classes',
    label: 'Classes',
    to: '/videos',
    synthetic: true,
  },
  programs: {
    action: 'Navigated to Programs',
    label: 'Programs',
    to: '/programs',
    synthetic: true,
  },
  shop: {
    action: 'Navigated to Shop',
    label: 'Shop',
    to: 'https://our-body-electric.myshopify.com/',
    synthetic: false,
  },
  guestPass: {
    action: obe.events.navigation.navigated_to_guest_pass,
    label: 'SEND GUEST PASS',
    to: '/guest-pass',
    synthetic: true,
  },
  bookmarks: {
    to: '/collections',
    label: 'Bookmarks',
    action: undefined,
    synthetic: true,
  },
  classStack: {
    to: '/class-stack',
    label: 'Class stack',
    action: undefined,
    synthetic: true,
  },
  profile: {
    to: '/profile',
    label: 'Profile',
    action: undefined,
    synthetic: true,
  },
  settings: {
    to: '/settings',
    label: 'Settings',
    action: undefined,
    synthetic: true,
  },
  manageSubscriptions: {
    to: '/plans',
    label: 'Manage subscriptions',
    action: undefined,
    synthetic: true,
  },
  changePassword: {
    to: '/change-password',
    label: 'Change password',
    action: undefined,
    synthetic: true,
  },
  courseworks: {
    to: '/courses',
    label: 'Courses',
    action: undefined,
    synthetic: true,
  },
} as const satisfies Record<string, NavLinkType>

const profileSubItems = ({ username }: { username?: string }) => [
  {
    label: 'Collections',
    to: `/${username || 'profile'}/collections`,
  },
  { label: 'Favorites', to: `/${username || 'profile'}/collections/favorites` },
  { label: 'Stats', to: `/${username || 'profile'}/stats` },
  { label: 'Friends', to: `/${username || 'profile'}/friends` },
  { label: 'Class History', to: `/${username || 'profile'}/class-history` },
]

export const menu = ({
  dayPastSubscription,
  isMember,
  username,
}: {
  dayPastSubscription: number
  isMember: boolean
  username?: string
}) => {
  const items = []

  if (isMember) {
    items.push(links.guestPass)
    items.push({
      label: 'Profile',
      to: `/${username || 'profile'}`,
      children: profileSubItems({ username }),
    })
    if (dayPastSubscription <= 30) items.push({ label: 'Getting Started', to: '/getting-started' })
  } else {
    items.push(links.aboutUs)
  }

  items.push({
    ...links.classes,
    children: [],
  })
  items.push(links.programs)

  if (!isMember) {
    items.push(links.collections)
  }

  items.push(links.instructors)

  if (isMember) {
    items.push({
      label: 'Community',
      to: 'https://www.facebook.com/groups/ourbodyelectricfam/',
      synthetic: false,
    })
  }

  items.push(links.blog)
  items.push(links.shop)

  return items
}

export const primary = (isMember: boolean, dayPastSubscription: number) => {
  const items = []

  if (!isMember) {
    items.push(links.aboutUs)
  }

  items.push(links.classes)
  items.push(links.programs)

  if (!isMember) {
    items.push(links.collections)
  }

  if (isMember) {
    items.push(links.instructors)
    if (dayPastSubscription <= 30) items.push({ label: 'Getting Started', to: '/getting-started' })
    items.push(links.guestPass)
  }

  return items
}

export const secondary = (isGuest: boolean, isMember: boolean) => {
  const items = []

  items.push(links.blog)
  items.push(links.shop)

  if (!isGuest && !isMember) {
    items.push(links.logout)
  }

  if (isGuest) {
    items.push(links.login)
  }

  return items
}

export const account = ({
  hasFreemiumEnabled,
  isNonbillable,
  isLargeAndUp,
  username,
}: {
  hasFreemiumEnabled: boolean
  isNonbillable: boolean
  isLargeAndUp: boolean
  username?: string
}) => {
  const items = []

  if (isLargeAndUp) {
    items.push({
      label: 'Profile',
      to: `/${username || 'profile'}`,
      children: profileSubItems({ username }),
    })
  }

  items.push({ label: 'Edit Profile', to: `/${username || 'profile'}/edit` })
  items.push({ label: 'Settings', to: '/settings' })

  if (!isNonbillable && !hasFreemiumEnabled) {
    items.push({ label: 'Manage Subscription', to: '/plans' })
  }

  items.push({ label: 'Change Password', to: '/change-password' })
  items.push(links.logout)

  return items
}
