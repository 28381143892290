import { Dropdown } from 'src/components/DropdownMenu'
import { Icon } from 'src/components/Icon'
import { navStyles } from './navStyles'
import { Link } from 'src/components/Link'
import { useRouter } from 'src/hooks/useRouter'

const tracking = { context: 'nav' }

export const ProgrammingMenu = () => {
  const [isOpen, setIsOpen] = React.useState(false)
  const { pathname } = useRouter()

  const onMenuLeave = () => {
    setIsOpen(false)
  }

  const onMenuHover = () => {
    setIsOpen(true)
  }

  return (
    <div onMouseLeave={() => setIsOpen(false)}>
      <div
        style={{ position: 'relative', cursor: 'pointer' }}
        onFocus={() => setIsOpen(true)}
        onBlur={() => setIsOpen(false)}
      >
        <Styles.ProgrammingItemContainer onMouseEnter={onMenuHover}>
          <Styles.ProgrammingItem>
            Workouts <Styles.ChevronIcon className="" />
          </Styles.ProgrammingItem>
        </Styles.ProgrammingItemContainer>

        <Styles.DropdownContainer onMouseEnter={() => setIsOpen(true)}>
          <Styles.Dropdown
            className=""
            hasFadeTransition
            isOpen={isOpen}
            onHover={() => setIsOpen(true)}
            onMenuLeave={onMenuLeave}
          >
            <Styles.Link
              action='Navigated to Classes'
              to='/videos'
              synthetic={false}
              tracking={tracking}
              isActive={pathname === '/videos'}
            >
              Classes
            </Styles.Link>
            <Styles.Link
              action='Navigated to Programs'
              to='/programs'
              synthetic={false}
              tracking={tracking}
              isActive={pathname === '/programs'}
            >
              Programs
            </Styles.Link>
            <Styles.Link
              action='Navigated to Collections'
              to='/obe-collections'
              synthetic={false}
              tracking={tracking}
              isActive={pathname === '/obe-collections'}
            >
              Collections
            </Styles.Link>
            <Styles.Link
              action='Navigated to Challenges'
              to='/challenges'
              synthetic={false}
              tracking={tracking}
              isActive={pathname === '/challenges'}
            >
              Challenges
            </Styles.Link>
          </Styles.Dropdown>
        </Styles.DropdownContainer>
      </div>
    </div>
  )
}

const Styles = {
  Dropdown: styled(Dropdown)`
    top: 10px;
    left: 0px;
    width: 179px;
    padding: 0 12px;
    z-index: 1001;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid var(--neutrals300);
    border-radius: 4px;
  `,
  DropdownContainer: styled.div`
    position: absolute;
  `,
  ProgrammingItemContainer: styled.div``,
  ProgrammingItem: styled.div`
    ${navStyles.landingLink}
  `,
  Link: styled(Link)`
    ${navStyles.landingLink}
    padding: 8px 0;

    ${({ isActive }: { isActive?: boolean }) =>
      isActive &&
      css`
        color: var(--primary400);
      `}
  `,
  ChevronIcon: styled(Icon.Chevron)`
    display: inline;
    width: 18px;
    height: 18px;
    margin-left: 3px;
    margin-bottom: 4px;
  `,
}
