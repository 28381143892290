import { useSearchParams } from 'next/navigation'
import { useEffect, useMemo } from 'react'

import { freemium } from 'src/constants'
import { storage } from 'src/storage'

export const useFreemiumPartnership = () => {
  const searchParams = useSearchParams()

  const isPartnershipFreemium = useMemo(() => {
    return (
      searchParams.get('utm_campaign')?.includes(freemium.utmCampaignPrefix) ||
      sessionStorage.getItem('freemiumPartnershipSlug')
    )
  }, [searchParams])

  useEffect(() => {
    const utmParam = searchParams.get('utm_campaign')
    if (utmParam?.includes(freemium.utmCampaignPrefix)) {
      storage.session.setItem(
        'freemiumPartnershipSlug',
        utmParam.replace(freemium.utmCampaignPrefix, ''),
      )
    }
  }, [searchParams])

  return {
    isPartnershipFreemium,
  }
}
