import { usePathname } from 'next/navigation'
import { useState } from 'react'

import { LandingHeaderDrawer } from 'src/components/App/Header/Landing/LandingHeaderDrawer'
import { LogoBlack } from 'src/components/App/Header/LogoBlack'
import { ProgrammingMenu } from 'src/components/App/Header/ProgrammingMenu'
import { navStyles } from 'src/components/App/Header/navStyles'
import { links } from 'src/components/App/Header/navigation'
import { CouponBanner } from 'src/components/Banners/Coupon/CouponBanner'
import { Link } from 'src/components/Link'
import { MorphismWrapper } from 'src/components/MorphismWrapper'
import { useFreemiumPartnership } from 'src/hooks/freemium/useFreemiumPartnership'
import { useSignupUrl } from 'src/hooks/useSignupUrl'
import { breakpointMedia } from 'src/styles'

const TourDialog = dynamic(
  () => import('src/components/TourDialog').then((mod) => mod.TourDialog),
  { ssr: false },
)

const tracking = { context: 'nav' }
const signupTracking = { context: 'nav bar' }

const signupClickHandler = () => {
  obe.analytics.track(obe.events.landing_page_cta_pressed, {
    location: 'nav bar',
    route: 'sign-up-flow',
  })
}

export const LandingHeader = () => {
  const signupUrl = useSignupUrl()
  const pathname = usePathname()

  const [isTourOpen, setTourOpen] = useState(false)

  const { isPartnershipFreemium } = useFreemiumPartnership()

  return (
    <MorphismWrapper
      className="h-[calc(var(--mobile-header-height)+var(--coupon-banner-height))]
        lg:h-[calc(var(--landing-header-height)+var(--coupon-banner-height))]"
    >
      <div
        data-testid='landing-header'
        className="h-[calc(var(--mobile-header-height)+var(--coupon-banner-height))] w-full
          lg:h-[calc(var(--landing-header-height)+var(--coupon-banner-height))]"
      >
        <div
          className="fixed left-0 top-0 z-[1000] h-[var(--mobile-header-height)] w-full transition-all duration-200
            ease-out lg:h-[var(--landing-header-height)]"
        >
          <CouponBanner />
          <div
            className="relative flex h-[var(--mobile-header-height)] flex-wrap items-center justify-between px-4 py-0
              lg:h-[var(--landing-header-height)] lg:px-[75px]"
          >
            <div className="flex w-full items-center justify-between">
              <section className="hidden flex-1 items-center gap-6 lg:flex">
                <StyledLink
                  action={links.aboutUs.action}
                  to={links.aboutUs.to}
                  synthetic={false}
                  tracking={tracking}
                  isActive={pathname === links.aboutUs.to}
                >
                  {links.aboutUs.label}
                </StyledLink>
                <ProgrammingMenu />
              </section>
              <LogoBlack to='/' />
              <section className="hidden flex-1 items-center justify-end gap-6 lg:flex">
                <StyledLink
                  action={links.login.action}
                  to={links.login.to}
                  synthetic={false}
                  tracking={tracking}
                >
                  Login
                </StyledLink>
                <AnchorButton
                  synthetic={false}
                  to={signupUrl}
                  onClick={signupClickHandler}
                  tracking={signupTracking}
                >
                  {isPartnershipFreemium ? 'Get started' : 'Start free trial'}
                </AnchorButton>
              </section>
              <section className="flex items-center gap-4 lg:hidden">
                <AnchorButton
                  synthetic={false}
                  to={signupUrl}
                  onClick={signupClickHandler}
                  tracking={signupTracking}
                >
                  {isPartnershipFreemium ? 'Get started' : 'Start free trial'}
                </AnchorButton>
                <LandingHeaderDrawer />
              </section>
            </div>
          </div>
        </div>
        {isTourOpen && (
          <TourDialog
            isOpen={isTourOpen}
            onClose={() => setTourOpen(false)}
            tourSlug='product-tour'
          />
        )}
      </div>
    </MorphismWrapper>
  )
}

const StyledLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})`
  ${navStyles.landingLink}

  ${({ isActive }: { isActive?: boolean }) =>
    isActive &&
    css`
      color: var(--primary400);
    `}
`

const AnchorButton = styled(Link)`
  ${navStyles.button}
  font-size: 13px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0.3px;
  text-align: center;
  text-transform: none;
  padding: 10px 20px;
  border-radius: 40px;
  ${({ hidden }: { hidden?: boolean }) =>
    hidden &&
    css`
      display: none;
    `}

  ${breakpointMedia.largeAndUp} {
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    padding: 15px 30px;
    border-radius: 40px;
  }
`
