import { createContext, useContext } from 'react'

interface DrawerState {
  isOpen: boolean
  toggle: () => void
  id?: string
  // To be deprecated when we move Display/Collapse to tailwind.
  width?: number
}

export const DrawerContext = createContext<DrawerState | null>(null)

export function useDrawerContext() {
  const context = useContext(DrawerContext)

  if (!context) {
    throw new Error('useDrawerContext must be used within a DrawerProvider')
  }

  return context
}
