import { breakpoint, mixin, button } from 'src/styles'

export const navStyles = {
  item: css`
    border-bottom: 1px solid #c4c4c4;
    padding: 12px 0;
    :last-child {
      border-bottom: 0;
    }
  `,
  link: css`
    border-bottom: 8px solid transparent;
    display: inline-block;
    font-size: 13px;
    line-height: 0px;
    letter-spacing: 0.015em;
    padding-top: 14px;
    text-transform: uppercase;
    :focus {
      outline: none;
      border-color: var(--yellow);
    }
    :hover {
      text-decoration: none;
      border-color: var(--yellow);
    }
  `,
  landingLink: css`
    display: inline-block;
    color: var(--black);
    font-size: 19px;
    font-weight: 300;
    line-height: 25px;
    letter-spacing: -0.02em;
    text-align: left;

    :focus {
      outline: none;
    }
    :hover {
      text-decoration: none;
      color: var(--primary400);
    }

    margin-right: 24px;
    position: relative;
  `,
  button: css`
    ${button.newPrimary}
    font-size: 13px;
    line-height: 13px;
    letter-spacing: 0.015em;
    padding: 12px 20px;
    text-transform: uppercase;
    border-radius: 1.5rem;
    :hover {
      text-decoration: none;
    }
  `,
  notificationDot: styled.div`
    height: 6px;
    width: 6px;
    background-color: var(--persianBlue);
    position: absolute;
    top: 23px;
    right: 69px;
    content: '';
    border-radius: 50%;

    ${breakpoint.mediumAndDown} {
      top: -11px;
      right: -7px;
    }
  `,
  friendRequestsBadge: styled.div`
    ${mixin.flexCenterBoth}
    height: 18px;
    min-width: 18px;
    width: fit-content;
    background-color: var(--persianBlue);
    position: absolute;
    top: -5px;
    right: -5px;
    content: '';
    border-radius: 50%;
    font-size: 9px;
    line-height: 10px;
    padding: 5px;
    color: white;
  `,
}
