'use client'

import { useEffect, useRef, type ElementRef } from 'react'
import { twMerge } from 'tailwind-merge'

import { useResizeObserver } from 'src/hooks/utils/useResizeObserver'
import { useCouponContext } from 'src/providers/CouponProvider/CouponContext'

export const CouponBanner = () => {
  const bannerRef = useRef<ElementRef<'div'> | null>(null)
  const { height } = useResizeObserver({ ref: bannerRef, box: 'border-box' })

  const { coupon, isValid } = useCouponContext()

  useEffect(() => {
    if (!bannerRef.current || !coupon?.metadata?.bannerText) return
    if (!isValid) {
      window.document.documentElement.style.setProperty('--coupon-banner-height', '0px')
    } else {
      window.document.documentElement.style.setProperty('--coupon-banner-height', `${height}px`)
    }
  }, [coupon?.metadata?.bannerText, height, isValid])

  return (
    <div
      ref={bannerRef}
      className={twMerge(
        `invisible w-full overflow-hidden bg-primary-400 !py-0 px-0 text-center text-white opacity-0
        transition-all duration-200`,
        [coupon?.metadata?.bannerText && isValid ? 'visible !py-4 px-8 opacity-100' : 'h-0'],
      )}
    >
      {coupon?.metadata?.bannerText}
    </div>
  )
}
