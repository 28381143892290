import type { ReactNode } from 'react'

import { useDrawerContext } from 'src/components/Display/Drawer/context/DrawerContext'
import { Hamburger } from 'src/components/Icon/Hamburger'
import { HamburgerClose } from 'src/components/Icon/HamburgerClose'

interface Props {
  children?: ReactNode
}

export const DrawerTrigger = ({ children }: Props) => {
  const { isOpen, toggle } = useDrawerContext()

  return children ? (
    <button onClick={toggle}>{children}</button>
  ) : (
    <button
      onClick={toggle}
      className="radius-none bg-transparent pl-2.5 pr-0 text-xl leading-5 text-black focus:outline-none"
    >
      {isOpen ? (
        <HamburgerClose className="h-5 w-5 stroke-black" />
      ) : (
        <Hamburger className="h-5 w-5 fill-black" />
      )}
    </button>
  )
}
