import type { CSSProperties, ReactNode } from 'react'
import { CSSTransition } from 'react-transition-group'

import { useMediaQuery } from 'src/hooks/window'
import { breakpointMediaShort } from 'src/styles'

interface CollapseProps {
  id?: string
  isOpen: boolean
  isHorizontal?: boolean
  transitionDuration?: number
  width?: number | null
  className?: string
  children: ReactNode
}

interface DimensionInterface {
  width?: number | null
  height?: string | number | null
}

export const Collapse = ({
  id,
  isOpen,
  isHorizontal,
  transitionDuration = 400,
  width = null,
  className,
  children,
}: CollapseProps) => {
  const isLargeAndUp = useMediaQuery(breakpointMediaShort.largeAndUp)
  const [dimension, setDimension] = React.useState<DimensionInterface | null>(null)

  const getDimension = (node: HTMLElement) =>
    isHorizontal ? { width } : { height: node.scrollHeight }
  const onEntered = () => setDimension({ height: 'unset' })
  const onEnteringAndExit = (node: HTMLElement) => setDimension(getDimension(node))

  return (
    <CSSTransition
      appear={isOpen}
      classNames='collapse'
      in={isOpen}
      onEntering={onEnteringAndExit}
      onExit={onEnteringAndExit}
      onExiting={() => setDimension(isHorizontal ? { width: 0 } : { height: 0 })}
      onExited={() => setDimension(isHorizontal ? { width: null } : { height: null })}
      timeout={300}
      {...(dimension?.height && { onEntered })}
    >
      <Container
        id={id}
        data-open={isOpen && !isLargeAndUp}
        className={className}
        isHorizontal={isHorizontal}
        style={dimension as CSSProperties}
        transitionDuration={transitionDuration}
      >
        {children}
      </Container>
    </CSSTransition>
  )
}

const Container = styled.div`
  overflow: hidden;

  ${({
    isHorizontal,
    transitionDuration,
  }: {
    isHorizontal?: boolean
    transitionDuration: number
  }) => css`
    transition: ${isHorizontal ? 'all 0.1s ease 0s' : `height ${transitionDuration}ms ease`};
    height: ${!isHorizontal && 0};
    width: ${isHorizontal && 0};
  `}
`
