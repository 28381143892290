export const useOuterClick = ({ onOuterClick, excluded = null }) => {
  const ref = React.useRef(null)

  const handleClickOutside = (event) => {
    const excludedClass = excluded && event.target.parentElement?.classList?.contains(excluded)
    if (ref.current && !ref.current.contains(event.target) && !excludedClass) {
      onOuterClick()
    }
  }

  React.useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { ref }
}
