'use client'

import * as Sentry from '@sentry/nextjs'
import { usePathname } from 'next/navigation'
import { useCallback, useEffect, useMemo, useState, type ReactNode } from 'react'
import useSWR from 'swr'
import useSWRImmutable from 'swr/immutable'

import { useSignUpContext } from 'src/hooks/useSignUpContext/useSignUpContext'
import {
  fetchStripeCoupon,
  getActiveCouponCode,
  removeStoredCouponCode,
  setStoredStripeCoupon,
} from 'src/hooks/useStripeCoupon'
import { CouponContext } from 'src/providers/CouponProvider/CouponContext'
import { useStoredCoupon } from 'src/providers/CouponProvider/useStoredCoupon'
import { couponValidators } from 'src/providers/CouponProvider/validationUtils'
import type { EnhancedCoupon } from 'src/types/Coupon'

interface Props {
  children?: ReactNode
  promoCode?: string | null
}

function shouldFetch(code: string, selectedPlanId: string | undefined) {
  return selectedPlanId && code ? `getCoupon/${code}/${selectedPlanId}` : null
}

export const CouponProvider = ({ children, promoCode = null }: Props) => {
  const pathname = usePathname()

  const canUpdateDefaultPlan = useMemo(() => {
    return routesAllowedToUpdateDefaultPlan.includes(
      pathname as (typeof routesAllowedToUpdateDefaultPlan)[number],
    )
  }, [pathname])

  const { selectedPlan, plans, onChangeSelectedPlan } = useSignUpContext()

  const [isValid, setIsValid] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const { storedCoupon } = useStoredCoupon()

  const [inputCode, setInputCode] = useState(promoCode || storedCoupon || '')
  const [fetchedCode, setFetchedCode] = useState(promoCode || storedCoupon || '')

  const { data: activeCouponCode, isValidating: isValidatingActiveCoupon } = useSWRImmutable(
    !promoCode && !storedCoupon && (pathname === '/' || pathname === '/membership/purchase')
      ? '/api/v4/active_coupon_codes/latest'
      : null,
    getActiveCouponCode,
  )

  const { data, isValidating } = useSWR<EnhancedCoupon, Error>(
    shouldFetch(fetchedCode, selectedPlan?.planIdentifier),
    () => fetchStripeCoupon(inputCode),
    {
      revalidateOnFocus: false,
      revalidateOnMount: !!inputCode.length,
      shouldRetryOnError: false,
      dedupingInterval: 0,
      onSuccess(coupon) {
        runCouponValidators(coupon)
      },
      onError() {
        setIsValid(false)
        setErrorMessage("This code doesn't exist.")
      },
    },
  )

  const runCouponValidators = useCallback(
    (coupon: EnhancedCoupon) => {
      for (const validator of couponValidators) {
        const validation = validator({
          coupon,
          allowedPeriod: selectedPlan?.periodMonthCount,
          canUpdateDefaultPlan,
        })
        if (!validation.isValid) {
          if (canUpdateDefaultPlan && validation.defaultPlanPeriod) {
            const newPlan = plans.find((p) => p.periodMonthCount === validation.defaultPlanPeriod)
            if (newPlan) {
              onChangeSelectedPlan(newPlan)
              continue
            }
          }
          setIsValid(false)
          setErrorMessage(validation.errorMessage)
          if (validation.shouldLog) {
            Sentry.captureException(validation.errorMessage, {
              level: 'warning',
              extra: {
                selectedPlan: selectedPlan?.planIdentifier,
                allowedPlans: coupon.allowedPlans,
                couponCode: fetchedCode,
              },
            })
          }
          return
        }
      }
      setIsValid(true)
      setErrorMessage(null)
    },
    [
      canUpdateDefaultPlan,
      fetchedCode,
      onChangeSelectedPlan,
      plans,
      selectedPlan?.periodMonthCount,
      selectedPlan?.planIdentifier,
    ],
  )

  const clearCode = useCallback(() => {
    setIsValid(false)
    setInputCode('')
    setFetchedCode('')
    setErrorMessage(null)
    removeStoredCouponCode()
  }, [])

  const onChangeInputCode = useCallback(
    (newCode: string) => {
      setInputCode(newCode.toUpperCase().trim())
      if (!newCode.length) {
        clearCode()
      }
    },
    [clearCode],
  )

  const validateCoupon = useCallback(() => {
    setFetchedCode(inputCode)
    setStoredStripeCoupon(inputCode)
  }, [inputCode])

  useEffect(() => {
    if (activeCouponCode) {
      setFetchedCode((prev) => prev || activeCouponCode)
      setInputCode((prev) => prev || activeCouponCode)
    }
  }, [activeCouponCode])

  return (
    <CouponContext.Provider
      value={{
        isValidating: isValidating || isValidatingActiveCoupon,
        isValid: isValid && !isValidating,
        inputCode,
        coupon: data ?? null,
        errorMessage,
        validateCoupon,
        onChangeInputCode,
        clearCode,
      }}
    >
      {children}
    </CouponContext.Provider>
  )
}

const routesAllowedToUpdateDefaultPlan = ['/'] as const
