interface UserActionProps {
  action?: string
  aria?: { [key: string]: unknown }
  children?: React.ReactNode
  isDisabled?: boolean
  multiAction?: string
  role?: string
  tracking?: { [key: string]: unknown }
  disableTracking?: boolean
  className?: string
  onClick: ((...args: unknown[]) => void) | (() => void) | (() => Promise<void>)
}

export const UserAction: React.FC<UserActionProps> = ({
  action,
  aria = {},
  className,
  children,
  isDisabled = false,
  multiAction,
  onClick,
  role = 'button',
  tracking,
  disableTracking = false,
}) =>
  React.createElement(
    Styles.Container,
    {
      ...aria,
      className,
      onClick: (event) => {
        event.stopPropagation()
        event.preventDefault()

        if (!isDisabled) {
          if (!disableTracking) {
            obe.analytics.track(action, tracking)

            if (multiAction) {
              obe.analytics.track(multiAction, tracking)
            }
          }
          onClick(event)
        }
      },
      onKeyPress: (event) => {
        event.stopPropagation()
        event.preventDefault()

        if (event.key === 'Enter' && !isDisabled) {
          if (!disableTracking) obe.analytics.track(action, tracking)
          onClick()
        }
      },
      isDisabled,
      role,
      tabIndex: isDisabled ? -1 : 0,
    },
    children,
  )

const Styles = {
  Container: styled.div<{ isDisabled: boolean }>`
    cursor: pointer;
    ${({ isDisabled }) =>
      isDisabled &&
      css`
        cursor: default;
        opacity: 0.25;
        :focus {
          outline: 0;
        }
      `}
  `,
}
