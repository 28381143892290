import { client } from 'src/api'
import { planLabelsByPeriod } from 'src/constants'
import type { Plan, PlanBase } from 'src/types/Plan'

export const DEFAULT_ANNUAL_PLAN = 'price_test_yearly_variant_c'
export const DEFAULT_MONTHLY_PLAN = 'price_test_monthly_variant_c'

export function getBasePlans() {
  return client.get<PlanBase[]>('/api/v4/plans?payment_provider=stripe')
}

export async function getPlans() {
  const { data: basePlans } = await getBasePlans()
  const plans = processBasePlan(basePlans)
  return plans.map(
    (plan) =>
      ({
        ...plan,
        monthlyPlanCost: basePlans.find((plan) => plan.periodMonthCount === 1)?.periodPriceCents,
      }) as Plan,
  )
}

export function processBasePlan(plans: PlanBase[]) {
  const monthlyPlan = plans.find((plan) => plan.periodMonthCount === 1)
  const baseCost = monthlyPlan?.periodPriceCents
  return plans.map(
    (plan) =>
      ({
        labels: planLabelsByPeriod[plan.periodMonthCount as keyof typeof planLabelsByPeriod],
        savings: baseCost
          ? (baseCost - plan.periodPriceCents / plan.periodMonthCount) * plan?.periodMonthCount
          : null,
        ...plan,
      }) as Plan,
  )
}
