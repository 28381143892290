import { CSSTransition } from 'react-transition-group'
import { useOuterClick } from 'src/hooks/useOuterClick'

const appearDuration = 300
const fadeTransitionName = 'fade'

export const Dropdown = ({
  children,
  className,
  hasFadeTransition = false,
  isOpen,
  onHover = Function.prototype,
  onMenuLeave = Function.prototype,
  onOuterClick = Function.prototype,
}) => {
  const { ref } = useOuterClick({ onOuterClick })

  if (hasFadeTransition)
    return (
      <CSSTransition
        appear={isOpen}
        classNames={fadeTransitionName}
        in={isOpen}
        onEnter={onHover}
        onExit={onMenuLeave}
        timeout={appearDuration}
        unmountOnExit
      >
        <Styles.FadeContainer className={className} key='transition'>
          {children}
        </Styles.FadeContainer>
      </CSSTransition>
    )

  if (isOpen && !hasFadeTransition)
    return (
      <Styles.Container className={className} ref={ref}>
        {children}
      </Styles.Container>
    )

  return null
}

const Styles = {
  Container: styled.div`
    position: absolute;
    z-index: 4;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 12px;
    background: white;
    margin-top: 10px;
    padding: 0 16px;
    width: 179px;

    button + button {
      border-top: 0.5px solid #d0d0d0;
    }
  `,
  FadeContainer: styled.div`
    position: absolute;
    z-index: 4;

    background: #ffffff;
    box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.2);
    opacity: 1;

    transition: opacity ${appearDuration}ms ease 0s;

    &.${fadeTransitionName}-enter {
      opacity: 0.4;
    }

    &.${fadeTransitionName}-enter-active {
      opacity: 1;
    }

    &.${fadeTransitionName}-exit {
      opacity: 0.4;
    }

    &.${fadeTransitionName}-exit-active {
      opacity: 0;
    }
  `,
}
