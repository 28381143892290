import { type ReactNode } from 'react'

import { DrawerContent } from 'src/components/Display/Drawer/DrawerContent'
import { DrawerOverlay } from 'src/components/Display/Drawer/DrawerOverlay'
import { DrawerTrigger } from 'src/components/Display/Drawer/DrawerTrigger'
import { DrawerProvider } from 'src/components/Display/Drawer/context/DrawerProvider'

interface Props {
  id?: string
  width?: number
  onToggle?: (open: boolean) => void
  content?: ReactNode
}

export const Drawer = ({ id, width = 260, onToggle, content }: Props) => {
  return (
    <DrawerProvider id={id} width={width} onToggle={onToggle}>
      <DrawerTrigger />
      {content}
    </DrawerProvider>
  )
}

Drawer.Overlay = DrawerOverlay
Drawer.Content = DrawerContent
